import React, { useEffect } from 'react';
import { Link } from 'react-router-dom' 
const Booklist = () => {
    useEffect(() => {
        document.title = 'Booklist - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Booklist</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Booklist</h1>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_1.pdf" target="_blank"><div className="sample-blk"><p>Class I</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_2.pdf" target="_blank"><div className="sample-blk"><p>Class II</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_3.pdf" target="_blank"><div className="sample-blk"><p>Class III</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_4.pdf" target="_blank"><div className="sample-blk"><p>Class IV</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_5.pdf" target="_blank"><div className="sample-blk"><p>Class V</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_6.pdf" target="_blank"><div className="sample-blk"><p>Class VI</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_7.pdf" target="_blank"><div className="sample-blk"><p>Class VII</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_8.pdf" target="_blank"><div className="sample-blk"><p>Class VIII</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_9.pdf" target="_blank"><div className="sample-blk"><p>Class IX</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_10.pdf" target="_blank"><div className="sample-blk"><p>Class X</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_11.pdf" target="_blank"><div className="sample-blk"><p>Class XI</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                <div className="col-lg-4 col-md-6"><Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/BOOK_LIST_12.pdf" target="_blank"><div className="sample-blk"><p>Class XII</p><img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/></div></Link></div>
                      </div>
            </div>
        </div>
     </>
  )
}

export default Booklist

