import React, { useEffect } from "react"; 
import { Link } from 'react-router-dom'
const MissionVision = () => {
    useEffect(() => {
        document.title = 'Mission & Vision, Motto & Aim - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li> Mission & Vision, Motto & Aim</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Mission & Vision, Motto & Aim </h1>
                    </div>
                </div>
                <div className="row">
    
                <div className="col-lg-12 col-md-12">
                <div className="box-pannel box-shadow">
                <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/mission.png" alt="mgmb"/> 
                <div>
                <h3 className="subhead black">Our Mission</h3>
                <p>In this temple of learning we :- Provide holistic education, imparted by caring &amp; dedicated faculty members in a contemporary learning environment. Focus on developing the cognitive, emotional, social, physical &amp; spiritual potential of every child. Evolve inclusive atmosphere within classroom situation where every child gets equal opportunity and space for expression and growth.</p>
                </div>
                </div>
                </div>   
                    <div className="col-lg-6 col-md-12">
                <div className="box-pannel box-shadow">
                <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/vision.png" alt="mgmb"/> 
                <div>
                <h3 className="subhead black">Our Vision</h3>
                <p>To develop our students as responsible youths with creative minds, a sense of empathy for others and the ability to enrich one's own identity with requisite skill and qualities to contribute positively to the society.</p>
                </div>
                </div>
                </div>   
                <div className="col-lg-6 col-md-12">
                <div className="box-pannel box-shadow">
                <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/aim.png" alt="mgmb"/>
                <div>
                <h3 className="subhead black"> Our Motto</h3>
                    <p className="mb-0"><i>Tamaso Ma Jyotirgamaya</i></p>
                <p>We aspire to dispel darkness through education and ignite the spark of enlightenment within every child to be creative, competitive and compassionate.</p>
                </div>
                </div>
                </div> 
                  <div className="col-lg-12 col-md-12">
                <div className="box-pannel box-shadow"> 
                <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/goal.png" alt="mgmb"/> 
                <div>
                <h3 className="subhead black">Our Aim</h3>
                <p>To create good and thinking individuals with positive attitude encompassing good values, besides academic excellence.</p>
                </div>
                </div>
                </div> 
                 
                    
                
                    
                </div>
            </div>
        </div>
     </>
  )
}

export default MissionVision

