import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const pdfLinks = [
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/COURSE-AFTER-CLASS-12-RELATED-PHYSICS.pdf", title: "COURSE AFTER CLASS 12 RELATED TO PHYSICS" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/IISERs_Admission_Brochure_2023.pdf", title: "IISERs Admission Brochure 2023" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/BoIugadmission2022du.pdf", title: "Bolug Admission" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/CUETUG2022IB.pdf", title: "Common University Entrance Test" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/Illustrations.pdf", title: "Illustrative Examples" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/CBSE_exam_preparation.pdf", title: "CBSE Exam Preparation Tips" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/CAREER OPPORTUNITIES INDIAN ARMED FORCES.pdf", title: "Career Opportunities in Indian Armed Forces" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/career_counseling.pdf", title: "Career and Counseling" },
  { href: "http://upscfever.com/upsc-fever/index.html", title: "Competitive Exam Guidelines" },
  { href: "https://knowledgeuniverseonline.com/14-vocational-courses-after-class-12th/", title: "Best Job Oriented Courses Forever" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/Career-counselling.pdf", title: "Compendium of Academic Courses" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/Careers_after_class_10___12.pdf", title: "Careers After Class 10th/12th" },
  { href: "https://webapi.entab.info/api/image/MGMB/public/pdf/Parents__Handbook_of_Careers_after_School", title: "Parents' Handbook" }
];

const CareerGuidance = () => {
  const [currentPdf, setCurrentPdf] = useState(null);

  const closePopup = () => setCurrentPdf(null);

  useEffect(() => {
    document.title = 'Career Guidance - Mar Gregorios Memorial Hr. Sec. School, Bokaro';
  }, []);

  return (
    <div className="counselling">
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link></li> 
          <li> School</li>
          <li>Career Guidance</li>
        </ul>
      </div> 
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Career Guidance</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
            <p>We at Mar Gregorios Memorial (MGM) Higher Secondary School aim to become a Leading Innovative School of Bokaro, bridging the gap between the school education, higher education and the industry. Career Guidance Cell at MGM continues to engage the students in various informative career-oriented sessions, panel discussions, one on one session to enhance the student’s competencies in selfknowledge, educational and occupational exploration &amp; career planning. Specific inputs are given to the students regarding career choices mapped and aligned to their passion/interests which would help the students to fulfil their career goals and serve as the bridge between their college experience and employment or graduate school, helping them apply what they have learned.</p>
                    <h3 className="subhead">What is Career Guidance &amp; Counselling and why it is important for a child?</h3>
                    <p>Choosing the right career is one of the most crucial decisions of a student’s life. Globalization along with rapid advances in technology have thrown open a plethora of new career options to the youth. This reality has created the need for Career Guidance to be an integral part of the education system. Most students choose careers based on their marks obtained and not on what they are passionate about.<br/>
                      Career Guidance &amp; Counselling develops a student’s competencies in self-knowledge, educational and occupational exploration &amp; career planning. Specific inputs are given to the students regarding career choices mapped and aligned with their passion/interests. Career Counselling helps the students to fulfil their career goals and serve as the bridge between their college experience and employment or graduate school, helping them apply what they have learned.<br/>
                      It is the continuous process of thinking about the interests, values, skills and preferences and exploring the learning options available for a child. It generally involves getting to know what your child wants with respect to career opportunities. It is a process that helps to design a career path for your child.</p>
                    <h3 className="subhead">Role of Career Guidance Cell at MGM</h3>
                    <p>The School has a robust Career Guidance and Counselling cell under the able leadership of qualified teachers which helps to create awareness amongst students so that they are in a position to make informed choices. It serves a number of functions like:-</p>
                    <ul className="checklist">
                      <li>Guiding the students throughout the school to help them focus on the right kind of skills, readings and exposure that would help them build their profile for admissions to various universities as well as for their careers</li>
                      <li>Helping students to identify their areas of interest and aptitude.</li>
                      <li>Organizing one on one session with the students and parents, guiding them to make the best education and career choices for themselves and regarding the “best fit” universities post-high school education.</li>
                      <li>Providing updated information regarding various choices of subject, courses &amp; colleges and the current career trends</li>
                      <li>Facilitating Career Aptitude Tests &amp; Interpretation. Also, ensuring the information is conveyed to all the stakeholders i.e. the student, parent and school</li>
                      <li>Helping students access and fill in forms for Universities within India and overseas and get them to begin the process well in time so that they do not miss out deadline</li>
                      <li>Giving sustained support in the admission process that includes writing out recommendation letters and helping with the Personal Statements to be submitted.</li>
                      <li>Organizing interactive and interesting career fairs at M.G.M School for the students. The faculty members &amp; senior admission team interact with the students to orient them about the various courses, career options available globally.</li>
                      <li>Preparing the students thoroughly for the Industry &amp; Universities. We are the Gateway to Higher Education embedded with a strong industry connect</li>
                      <li>Provides information and introductory preparation for IELTS, TOFEL, SAT etc.</li>
                    </ul>
                    <h3 className="subhead">Who We Serve</h3>
                    <ul className="checklist">
                      <li><strong>Students from Grade 8 to 10 </strong>– We help the student of Grade 8- 10 to choose the right stream to join depending upon the child’s potential, interest, study habits and mark scoring pattern in the last three academic years.</li>
                      <li><strong>Students of Grade 11 &amp; 12</strong> – The students of Grade 11 &amp; 12 are guided to pick the right course and career, depending upon the child’s potential, interest, personality, study habits and mark scoring pattern in the last three academic years along with higher education guidance &amp; counseling for within the country and mission abroad.</li>
                      <li><strong>MGM Parents </strong>– Providing the parent only the relevant, accurate comprehensive information of the counselling proceedings, solving their career related queries.</li>
                    </ul>
              <ul className="checklist">
                {pdfLinks.map((pdf, index) => (
                  <li key={index}>
                    <Link 
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentPdf(pdf.href);
                      }}
                    >
                      {pdf.title} <span>Click Here</span>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="row">
                      <div className="col-md-6">
                        <h3 className="subhead">Career And Guidance Cell Incharges </h3>
                        <ul className="checklist">
                          <li>Mrs. Poonam Jha (Class XI-XII) - (8340173013)</li>
                          <li>Mrs. Tripti Pathak (Class IX-X) - (8252213289)</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <h3 className="subhead">Counselling Cell</h3>
                        <p><b>1. School Counsellor</b> - Mrs. Tripti Pathak</p>
                        <p><b>2. Cell Members</b> - Teacher Incharge</p>
                      </div>
                      <div className="clr10"></div>
                      <div className="col-md-6">
                        <h3 className="subhead">Senior Wing:-</h3>
                        <ul className="checklist">
                          <li><strong>Class 12</strong> - Mr. Chanchal (9471564208)</li>
                          <li><strong>Class 11</strong> - Mrs. Divya (6203369942)</li>
                          <li><strong>Class 10</strong> - Mr. Law Kumar (8987898784)</li>
                          <li><strong>Class 9</strong> - Mrs. Mala Sinha (9006811313)</li>
                          <li><strong>Class 8</strong> - Mr. Bhavesh Jha (9331740253)</li>
                          <li><strong>Class 7</strong> - Mrs. Ruby Ray (8210988188)</li>
                          <li><strong>Class 6</strong> - Mrs. Shazia Sadaf (8252526210)</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <h3 className="subhead">Primary Wing:-</h3>
                        <ul className="checklist">
                          <li><strong>Class 1</strong> - Mrs. Shubhra  Bhushan (8240628429)</li>
                          <li><strong>Class 2</strong> - Mrs.  Aparna Gupta (7667464911)</li>
                          <li><strong>Class 3</strong> - Mrs.  Anjali Mukherji (9430332030)</li>
                          <li><strong>Class 4</strong> - Mrs. Ranjita Giri (7061118301)</li>
                          <li><strong>Class 5</strong> - Mrs.  Rachna Singh (9570090016)</li>
                        </ul>
                      </div>
                    </div>
            </div>
          </div>
        </div>
      </div>

      {currentPdf && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="popup-close" onClick={closePopup}><i class="bi bi-x"></i></button>
            <iframe src={currentPdf} title="Career Guidance Document"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerGuidance;
