import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getActivity } from '../Service/Api';

const Activity = () => { 
    useEffect(() => {
        document.title = 'Activity - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []); 
    {/*   const [selectedOption, setSelectedOption] = useState('option2024');
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [year, setYear] = useState([]);
   
    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getActivity();
            galleryData.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order
            setData(galleryData);
            const uniqueYears = Array.from(
                new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
            );
            uniqueYears.sort((a, b) => b - a);
            setYear(uniqueYears);
            console.log(galleryData);
            setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
    }, []);
   
    useEffect(() => {
        console.log("events_data", data);
    }, [data]);
   
    const emptyArray = [
        { title: "Title", date: "DD/MM/YYYY" },   
        { title: "Title", date: "DD/MM/YYYY" },   
        { title: "Title", date: "DD/MM/YYYY" },   
    ];
   
    useEffect(() => {
        // Filter data based on selected year
        if (selectedYear && selectedYear !== "All") {
            const filteredData = data.filter((item) => {
                const itemYear = new Date(item.date).getFullYear();
                  
                return itemYear === parseInt(selectedYear);
            });
            setFilteredData(filteredData);
        } else {
            // If selected year is "All" or not selected, set filterData to all data
            setFilteredData(data);
        }
    }, [selectedYear, data]); // Added 'data' as a dependency
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate(); 
        const month = monthsOfYear[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year} `;
    };
    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    console.log(data) */}
 
   
    return (
        <>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li> 
            <li>  Media</li>
             <li>Activity</li>
        </ul>
    </div> 
    <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Activity</h1>
                </div>
            </div>
                <div className="row">
                        {/* <div className="col-lg-12">
                        <div className="chooseyear">
                             
                            <select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                                className="yearSelection"
                            >
                                <option value={"All"}>All</option>
                                {year.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                ))}
                            </select>
                        </div>
                        <div className="clr"></div>
                        {selectedOption === 'option2024' &&  
                            <div className="row">
                            {filterData.length > 0 ? (
                                filterData.map((item, index) => { 
                                        const date = new Date(item.date);
                                        const day = date.getDate();
                                        const month = monthsOfYear[date.getMonth()];
                                            console.log(date);
                                        return (
                                        <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-sm-12"> 
                                            <div className="activitybox"> 
                                                <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">
                                                    <div> 
                                                        <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} className="img-fluid" alt="MGMB" />
                                                        <h3>{item.title}</h3> 
                                                        <p>{formatDate(item.date)}   </p> 
                                                    </div> 
                                                </Link>
                                            </div>
                                        </div>
                                        );
                                    })
                                  ) : (
                                    emptyArray.map((data, index) => (
                                        <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                        <div className="activitybox"> 
                                         <Link to="#" target="_blank">
                                            <div> 
                                                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/gal-1.jpg" className="img-fluid" alt="MGMB" />
                                                <h3>{data.title}</h3> 
                                                <p>{data.date}</p> 
                                            </div> 
                                        </Link>
                                    </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        }
                    </div>  */}

                    <div className="col-lg-4">
                    <div className="activitybox"> 
                            <Link to="#" target="_blank">
                            <div> 
                                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/gal-1.jpg" className="img-fluid" alt="MGMB" />
                                <h3> Title</h3> 
                                <p> 29 July 2024 <i className="bi bi-paperclip"></i></p> 
                            </div> 
                        </Link>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="activitybox"> 
                            <Link to="#" target="_blank">
                            <div> 
                                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/gal-1.jpg" className="img-fluid" alt="MGMB" />
                                <h3> Title</h3> 
                                <p> 29 July 2024 <i className="bi bi-paperclip"></i></p> 
                            </div> 
                        </Link>
                    </div>
                    </div>
                        <div className="col-lg-4">
                        <div className="activitybox"> 
                                <Link to="#" target="_blank">
                                <div> 
                                    <img src="https://webapi.entab.info/api/image/MGMB/public/Images/gal-1.jpg" className="img-fluid" alt="MGMB" />
                                    <h3> Title</h3> 
                                    <p> 29 July 2024 <i className="bi bi-paperclip"></i></p> 
                                </div> 
                            </Link>
                        </div>
                        </div>
                     
                </div>
            </div> 
            </div>
        </>
    );
}

export default Activity;
