import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick"; 
import {getEvents} from'../Service/Api'
const HomeEvent = () => {
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(null); 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
   useEffect(() => {
       const fetchData = async () =>{
              try{
                 const eventData = await getEvents();
                 setData(eventData) ;  
              }catch(error){
               setError(error);
              }finally{
               setLoading(false)
              }
       }
       fetchData();
   },[]);

   const emptyArray = [
      { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
      { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"}, 
      { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"},
      { title:"Event Title",description:"Stay Tunned For More Updates",date:"DD.MM",time:"00:00",venue:"School Campus"}

   ] 
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <div className="eventbg">
          <Slider {...settings}>
          {data && data.length > 0 ? (data.map((item,index) => {  
            const date = new Date(item.date);
            const mon = monthNames[date.getMonth()];
            const eventdate = date.getDate();
                 console.log(mon);

             return (
            <div className="item" key={index}>
                               <div className="homeeventbox">
                              <div className="homeeventboximg">
                                {item.images.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid"   alt="MGMB"/>):(<img src='https://webapi.entab.info/api/image/MGMB/public/Images/events.jpg' className="img-fluid"   alt="MGMb"/>)}
                                <div className="homeeventboxbottom">
                                  <span>{eventdate} {mon} </span>
                                </div>
                              </div>
                              <div className="homeeventboxdesc">
      
                                <div className="homeeventboxtitle"><p className="title-evt">{item.title}</p>
                                  <p>{item.description}</p>
                                  <p className="location">{item.time} <span> {item.venue} </span></p>
                                </div>
      
                              </div>
                            </div>
                      </div>  
                      )
                    })) : (emptyArray.map((item,index) => (
           <div className="item" key={index}>
                                <div className="item">
                    <div className="homeeventbox">
                      <div className="homeeventboximg">
                        <img src="https://webapi.entab.info/api/image/MGMB/public/Images/events.jpg" className="img-fluid" alt="MGM bokaro" />
                        <div className="homeeventboxbottom">
                          <span>{item.date} </span>
                        </div>
                      </div>
                      <div className="homeeventboxdesc">
      
                        <div className="homeeventboxtitle"><p className="title-evt">{item.title}</p>
                          <p>{item.description}</p>
                          <p className="location">{item.time} <span> {item.venue} </span></p>
                        </div>
      
                      </div>
                    </div>
                  </div>
                      </div> 
                      )))}   
          </Slider>
          <button className="button">
          <Link to="/Events"> View All</Link> 
          </button>
        </div>
      </div>
    </>
  )
}

export default HomeEvent