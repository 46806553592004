import React, { useEffect } from "react";
import Popup from '../Component/Popup'
import HomeSlider from '../Component/HomeSlider'
import TopperXII from '../Component/TopperXII'
import TopperX from '../Component/TopperX'
import Birthday from '../Component/Birthday'
import Events from '../Component/Events'
import News from '../Component/News'
import Campuscare from '../Component/Campuscare'
import OnlineRegistration from '../Component/OnlineRegistration'
// import Facilities from'../Component/Facilities'
import Gallery from '../Component/Gallery'
import Infrastructure from '../Component/Infrastructure'
// import Message from '../Component/Message'
import { Link } from 'react-router-dom'
import Message from '../Component/Message'
import PrincipalMessage from'../Component/PrincipalMessage'
const Home = () => {
  useEffect(() => {
    document.title = 'Home - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
    <>
    
      <Campuscare />
      <div className="social-media">
        <ul>
        <li><a href="https://instagram.com/mgmbokaro?igshid=YmMyMTA2M2Y=" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/insta.png" /></a></li>
                          <li><a href="https://www.facebook.com/mgmhssbokaro?mibextid=ZbWKwL" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/facebook.png" /></a></li>
                          <li><a href="https://www.youtube.com/channel/UCF6_t0G_mmM9Ba31BbVvBZQ" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/youtube.png" /></a></li>
                          <li><a href="https://www.linkedin.com/in/mgmhss-bokaro-6791b0268/" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/linkdin.png" /></a></li>
        </ul>
      </div>
      <Popup />
      <OnlineRegistration />
      {/* <div className='chatbot'>
        <img src='https://webapi.entab.info/api/image/MGMB/public/Images/chatbot.gif' />
      </div> */}
      {/* <Popup /> */}
      
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3 col-md-12 padding-0 order-lg-1 order-md-2'>
            <div className="about-news">
              <News />
            </div>
          </div>
          <div className='col-lg-9 col-md-12 padding-0 order-1'>
            <HomeSlider />
          </div>
        </div>
      </div>


      <div className="aboutmidsec padded-sec">
        <div className='button'>
          <p><Link to="https://drive.google.com/file/d/1m2hXagFnN3K1dztu71A4SdDlNLXGpMzg/view?usp=sharingand" target="_blank">CBSE Disclosure</Link></p>
        </div>
        <div className='button'>
          
        <p> <Link to="https://www.mgmbcampuscare.in/" target="_blank">Online Payment</Link></p>
        </div>
        <div className='button'>
          <p> <Link to="http://www.mgmbcampuscare.in/Logon/OnlineStaffRecruitment" target="_blank">Staff Recruitment</Link></p>
        </div>
        <div className="phone"><Link to="https://www.youtube.com/watch?v=wr6jGaAY1k0" target='_blank'><img src="https://webapi.entab.info/api/image/MGMB/public/Images/360-view.gif" /></Link></div>
        <div className='button light'>
        <p><Link to="https://www.mgmbcampuscare.in/" target="_blank">Alumni Registration</Link></p>
        </div>
        <div className='button'>
          <p><Link to="/Magazine"> School Magazine </Link></p>
        </div>
        <div className='button'>
          <p><Link to="/BusRoute"> Bus Route </Link></p>
        </div>
      </div>

      <div className='aboutSection padded-sec'>
        <div className='container-fluid'>
          <div className='row'>


            <div className='col-lg-12 abt-content-block'>
              <div className='abt-content section-title'>
                <div className='title'>
                  <p>ABOUT US</p>
                  <h2>MAR GREGORIOS MEMORIAL</h2>
                </div>
                <p>M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel City is one of the best English Medium Co-Education Institution under the aegis of the Calcutta Diocesan Council of the Indian Orthodox Church. Late Lamented his Grace Dr. Stephanos Mar Theodosius, an eminent educationist, was the Founder President of this school. Rev. Fr. O. Thomas was the founder principal of this institution with whose hard work and vision resulted in the establishing of this prestigious intuition. The Great Scholar, H. G. Alexios Mar Eusebius is the current President of the school. We have thirty two distinct educational institutions spread in various States from Nagpur to Arunachal Pradesh under Calcutta Diocese of the Indian Orthodox Church. The School building is located in a sprawling campus spread out in 08 acres of land granted by the Management of Bokar</p>
                <button className='button'><Link to="/OurSchool"> READ MORE </Link></button>
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='about-img'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/video.png" className='img-fluid' />
              </div>
            </div>

            <div className='col-lg-5'>
              <div className='about-flex'>
                <img src='https://webapi.entab.info/api/image/MGMB/public/Images/patrons.png' />
                <div>
                  <p>St. Gregorios of Parumala</p>
                  <p><span>Our Patron</span></p>
                </div>
              </div>
              <div className='about-flex'>
                <img src='https://webapi.entab.info/api/image/MGMB/public/Images/founder.png' />
                <div>
                  <p>Dr. Stephanos Mar Theodosius</p>
                  <p><span>Founder</span></p>
                </div>
              </div>
              <div className='about-flex'>
                <img src='https://webapi.entab.info/api/image/MGMB/public/Images/chairman.png' />
                <div>
                  <p>H.G. Alexios Mar Eusebius</p>
                  <p><span>Chairman</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='four-block padded-sec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='four-blk'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/mission.gif" />
                <h3>OUR MISSION</h3>
                <p>In this temple of learning we :- Provide holistic education, imparted by caring & dedicated faculty members in a contemporary learning environment. Focus on developing the cognitive, emotional, social, physical & spiritual potential of every child.</p>

              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='four-blk'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/vision.gif" />
                <h3>OUR VISION</h3>
                <p>To develop our students as responsible youths with creative minds, a sense of empathy for others and the ability to enrich one's own identity with requisite skill and qualities to contribute positively to the society.</p>

              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='four-blk'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/motto.gif" />
                <h3>OUR MOTTO</h3>
                <p><span>Tamaso Ma Jyotirgamaya</span>We aspire to dispel darkness through education and ignite the spark of enlightenment within every child to be creative, competitive and compassionate.</p>

              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='four-blk'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/aim.gif" />
                <h3>OUR AIM</h3>
                <p>To create good and thinking individuals with positive attitude encompassing good values, besides academic excellence.</p>

              </div>
            </div>

            <div className='col-lg-12'>
              <button className='button'><Link to="/MissionVision"> READ MORE </Link></button>
            </div>
          </div>
        </div>
      </div>

      <div className="toper-birthday padded-sec">
        <div className="container-fluid section-title">
          <div className="row">
            <div className="col-lg-8">
              <div className='top-ach'>
              <div className='title'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/topper.png" />
                <p>OUR SHINING STARS</p>
                <h2>TOP ACHIEVERS</h2>

              </div>
              <div className='toper-block'>
                <div>
                  <h2>TOPPERS X</h2>
                  <TopperX />
                  <button className='button'><Link to="/Toppers"> View All </Link></button>
                </div>
                <div>
                  <h2> TOPPERS XII</h2>
                  <TopperXII />
                  <button className='button'><Link to="/ToppersXII"> View All </Link></button>
                </div>

              </div>
              </div>


            </div>
            <div className="col-lg-4 birthday_section">
              <div className='title'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/birthday.png" />
                <p>STARS OF THE DAY</p>
                <h2>HAPPY BIRTHDAY</h2>
                
              </div> 
              <Birthday />              
              
            </div>
            <div className="col-lg-4">

            </div>
          </div>
        </div>
      </div>
      <div className='message_section padded-sec'>
        <div className='container-fluid'>
          <div className='row'>
          <div className="col-lg-12 section-title">
              <div className='title'>
                <p>FROM THE DESK OF</p>
                <h2>MESSAGE SECTION</h2>
              </div>
            </div>
            <div className='col'>
              <Message />
            </div>
          </div>
        </div>
      </div> 
      <div className='infrastructure'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
            <div className='title'>
                  <p>Facilities in The School</p>
                  <h2>Infrastructure</h2>
                </div>
<Infrastructure />
            </div>
          </div>
        </div>
      </div>
      
      <div className="event padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className="col-lg-12 section-title">
              <div className='title'>
                <p>happening in  our school</p>
                <h2>UPCOMING EVENTS</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Events />
              {/* <Message /> */}

            </div>
          </div>
        </div>
      </div>
      <div className="homemsg padded-sec">
      <div className="container-fluid">
         <div className="row">
           <div className="col-xl-12 col-lg-12">
               
                 <PrincipalMessage/>
                 
          </div>
          </div>
     </div>
   </div>
   
      {/* <div className='management padded-sec'>
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-12 section-title'>
              <div className='title'>
                <p>stars of the day</p>
                <h2>school management</h2>
              </div>
            </div>

          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='management-list'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/management1.png" className='img-fluid' />
                <p>Rt. Rev. Dr. Mathew Nellikunnel CST</p>
                <p><span>patron of the school</span></p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='management-list'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/management2.png" className='img-fluid' />
                <p>Rev. Fr Sibichan</p>
                <p><span>Manager</span></p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='management-list'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/management3.png" className='img-fluid' />
                <p>Rev. Fr Suresh K V</p>
                <p><span>Principal</span></p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='management-list'>
                <img src="https://webapi.entab.info/api/image/MGMB/public/Images/management4.png" className='img-fluid' />
                <p>Rev. Fr Jestin Joseph</p>
                <p><span>vice principal</span></p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="homegallery padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className='col-md-12 section-title'>
              <div className='title'>
                <p>Some Precious Moments</p>
                <h2>Photo Gallery</h2>
              </div>
            </div>
            <div className="col-lg-12">

              <Gallery />
              <button className='button'><Link to="/Gallery"> View All </Link></button>
            </div>
          </div>
        </div>
      </div>
    
    </>

  )
}

export default Home
