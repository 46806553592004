import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
const OurPatron = () => {
    useEffect(() => {
        document.title = 'Our Patron - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>School Timing</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> School Timing </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="subhead">Class I to V School Timing</h3>
                        <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/MGMB/public/Images/I-V-timing.jpg"> <img src="https://webapi.entab.info/api/image/MGMB/public/Images/I-V-timing.jpg" className="d-block mx-auto img-fluid" alt="MGMB"/></a> 
                    </div> 
                    <div className="col-md-6">
                    <h3 className="subhead">Class VI to XII School Timing</h3> 
                    <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/MGMB/public/Images/VI-XII-timing.jpg"> <img src="https://webapi.entab.info/api/image/MGMB/public/Images/VI-XII-timing.jpg" className="d-block mx-auto img-fluid" alt="MGMB"/></a> 
                    </div>
              </div>
            </div>
        </div>
     </>
  )
}

export default OurPatron

