import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const OurPatron = () => {
    useEffect(() => {
        document.title = 'Our Patron - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  School</li>
                 <li>Our Patron</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Patron </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="https://webapi.entab.info/api/image/MGMB/public/Images/patron.jpg" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p>Metropolitan Geevarghese Mar Gregorios of Blessed Memory</p>
<p>Former Metropolitan of the Diocese of Niranam</p>
<p>
Saint Gregorios of Parumala, is the first declared saint of Malankara Orthodox Church of Kerala, India. Popularly known as Bishop of Parumala, shines in the cloud of witnesses as a bright luminary emitting rays of hope and courage to millions in their sufferings and struggles. Mar Gregorios was born on 15th June 1848 to Kochu Mathai and Mariam of Pallathetta family in the Chathuruthy house in Mulamthuruthy. He was called by the name ‘Kochaippora’ and was given the baptismal name ‘Geevarghese’. Kochaippora had two brothers and two sisters: Kurian, Eli, Mariam and Varkey. Kochaippora was the youngest of all and was therefore the most beloved to everyone. Unfortunately, his mother passed away when he was only two years old. His eldest sister Mariam became all that a mother meant for him. Mariam was already married and had a child of Kochaippora’s age.</p>
<p>On September 14, 1858, at the tender age of 10, he was ordained as reader-deacon (Korooyo) by Palakkunnathu Mathews Mar Athanasios at the Karingachira Church.  On December 10, 1876, when he was just 28 years old, he was consecrated as Bishop and Metropolitan and was given the new name Geevarghese Mar Gregorios. His Grace was appointed as Bishop of Niranam Diocese When His Grace took charge of Niranam Diocese, he shifted his residence to Parumala. Mar Gregorios engaged in a three-fold activity of tireless service for the church: Diocesan administration, Ministerial formation of deacons, Missionary witness of the church through inner spiritual and theological consolidation, along with evangelical outreach.</p>
<p>Mar Gregorios engaged himself in educational activities especially in facilitating primary education and English teaching without discriminating against caste, creed, gender or religion. Accordingly, he started schools at Kunnamkulam, Mulamthuruthy, Niranam, Thumpamon, Thiruvalla etc. He was a devout person of prayer and fasting. By his powerful prayers, people experienced miraculous healing and deliverance from satanic influences. According to him, “Prayer is the Inspiration of childhood, the Refuge of youth and Peace during old age."</p>
<p>His Blessed Soul left the Earthly Abode on 2nd November 1902. </p>
<p>In 1947, Mar Gregorios of blessed memory was declared a saint by the then His Holiness Baselius Geevarghese II. Today, irrespective of cast and religion, people in thousands visit the church and pray at his tomb, invoking the saintly intercession of Mar Gregorios, which made Parumala Church and the Tomb a Center of Pilgrimage.</p>
<p>In thy place of rest, O Lord, may this saint repose. </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default OurPatron

