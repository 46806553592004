import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const Founder = () => {
    useEffect(() => {
        document.title = 'Our Founder - Dr. Stephanos Mar Theodosius';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>School</li>
                 <li>Our Founder</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Founder </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="https://webapi.entab.info/api/image/MGMB/public/Images/founder1.png" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p>His Grace Dr. Stephanos Mar Theodosius of Blessed Memory</p>
                    <p>Former Metropolitan of the Diocese of Kolkata</p>
<p>God chooses great leaders to lead his people and to be good stewards in His kingdom. His Grace Dr. Stephanos Mar Theodosius, the former Bishop of Kolkata Diocese of the Indian Orthodox Church, was one such visionary leader. He was born on 2 October1924 in Pathamoothom, Kottayam, Kerala and was named Punnose by his parents.</p>
<p>After his ordination, Fr. Punnose, started his services as Parish Priest in Jabalpur, Madhya Pradesh in the year 1952.  He was appointed as the Director of the newly founded St. Thomas Orthodox Church Mission at Bhilai in 1972.   Driven with a burning zeal to serve the downtrodden, he whole-heartedly accepted the post.</p>
<p>In 1975, Fr. Punnose was consecrated as Bishop Stephanos Mar Theodosios. He became the first Bishop of the Diocese of Madras and later, in 1979, when the Diocese of Kolkata was formed, he became its first Bishop.</p>
<p>The activities of St. Thomas Mission became more extensive and increased rapidly under His Graces’ vision and guidance. Educational institutions, health service centers, village development centers, balagrams (Children’s homes), rehabilitation centers, ashrams and convents were successfully established in the states from Madhya Pradesh to Nagaland.</p>
<p>His Grace’s dedicated service, persistent hard work and love for education produced a good number of educational institutions across his pastoral domain. More than two dozen schools and colleges as well as a number of balwadis were established in Central and Eastern India, with the aim of uplifting and providing the best education especially to the underprivileged sections of the society.  Health service centres, village development centres, balagrams (Children’s homes), rehabilitation centres, were successfully established for the needy.</p>
<p>His Grace, whose missionary zeal and vision contributed phenomenally to the fields of education and health sectors in general and the upliftment of the marginalized and tribals in particular, was a much acclaimed and respected personality in religious, lay, private, public and government circles. Though His Grace left for his heavenly abode on 5 November 2007, his valuable and innumerable contributions remain as a glorious legacy of this great visionary and Man of God.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Founder

