
import React, { useEffect } from "react"; 
import { Link } from 'react-router-dom' 
const Facilities = () => {
  useEffect(() => {
    document.title = 'Facilities - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Infrastructure</li>
                 <li>Facilities</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Facilities</h1>
                    </div>
                </div>
                <div className="row facilities"> 
                <div className="col-12">
                      <h3 className="subhead">Sports</h3>
                      <div className="facilities-img1 left-block">
                      <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci1.jpg" alt="mgmb"/> 
                      </div>
                      <p className="first-letter">Regular and continuing PT classes is an essential part of the school curriculum. 'A Healthy Mind in A Healthy Body' is the dictum we emphasize in the School. Talented candidates in various fields of sports are selected, guided and trained to partake in inter-school competitions such as, Volleyball, Basketball, Chess, Cricket and Football etc. Qualified coaches are engaged to augment the stamina and style of players who may later choose a career in one of the many disciplines. Inter class competitions of indoor games like carrom, table-tennis, chess, Judo, Ghatka, Badminton, Kho-Kho, Kabaddi & Athletics etc. are organized with great enthusiasm and vigour.</p>
                </div>
                <hr/>
                    <div className="col-12">
                      <h3 className="subhead">Smart Classes</h3>
                      <div className="facilities-img right-block">
                      <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci2.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter"> Our Smart Class is equipped with hi-tech Audio-visual systems. It consists of multimedia projector, PC, DVD Player, Mikes and Speakers and other necessary paraphernalia. It gives a clear picture and understanding of the topic under analysis to the students.</p>
                      <br/>
                    </div>
                <hr/>
                <div className="col-12">
                      <h3 className="subhead">Test Engine</h3>
                      <div className="facilities-img1 left-block">
                      <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci3.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter">This feature helps the students to prepare themselves better before the exams. It furnishes them with the model question papers that are likely to appear in the examination.</p> 
                      <p>Students may login in their Control Panel to take advantage of this feature. This is very helpful to generate confidence, specially for those who get nervous before exams.</p>
                      <br/>
                </div>
                <hr/>
                    <div className="col-12">
                      <h3 className="subhead">Language Lab</h3>
                      <div className="facilities-img right-block">
                      <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/lang-lab2.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter"> To improve communication skill in English, the School in arrangement with Uolo, have developed a full-fledged language training system for our students.</p>
                      <br/>
                    </div>
                <hr/>
                <div className="col-12">
                      <h3 className="subhead">Transport</h3>
                      <div className="facilities-img1 left-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci5.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter">Students who would like to avail to and fukk commutation facilities by bus/tempo/rickshaws. etc as per availability may do so through contract transport arrangement made by the School. Transport Rules are fixed and they are normally not changed.</p> 
                      <p>Information regarding School Bus Routes / other modes etc. are supplied to all parents and wards to know the mode and number of vehicles coming to their bus stop, pickup and drop timings etc. The information is also available online at the school website.</p>
                      <br/>
                </div>
                <hr/>
                    <div className="col-12">
                      <h3 className="subhead">Co-Curricular Activity</h3>
                      <div className="facilities-img right-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci6.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter"> Co-Curricular activities are organised in the school from time to time to explore the hidden talents in our students and also to guide them accordingly. Arts &amp; Craft, Fancy Dress competition, Quiz etc. are some of them, but are not exhaustive.</p>
                      <br/>
                    </div>
                {/* <hr/>
                <div className="col-12">
                      <h3 className="subhead">Complain &amp; Suggestion</h3>
                      <div className="facilities-img1 left-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci7.jpg" alt="mgmb"/> 
                      </div>
                      <p className="first-letter">This feature has been brought into fore to enable us to attend to your Complaints &amp; Suggestions. Authentication is required before sending any complaints or suggestions. If found authentic, necessary remedial actions will be taken to resolve the issues as soon as possible (say, within 7 working days). You are also free to send your Feedback Reports, for such reports might help us eliminate avoidable odds. All your complaints, suggestions, feedback etc are welcome.</p>
                      <br/>
                </div> */}
                <hr/>
                    <div className="col-12">
                      <h3 className="subhead">Gym Room </h3>
                      <div className="facilities-img left-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci8.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter">  A strong body and mind reflects the student’s personality and attitude. Keeping this fact in mind we have a well equipped Gym for students. After the school hours they are allowed to use the facility. </p>
                      <br/>
                    </div>
                <hr/>
                <div className="col-12">
                      <h3 className="subhead">Parent Portal</h3>
                      <div className="facilities-img1 right-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci9.jpg" alt="mgmb"/> 
                      </div>
                      <p className="first-letter"> To have better interaction and dialogue between parents and school, a Parent/Student Portal is launched in collaboration with Entab Campus Software Solutions, New Delhi. Parents can have exclusive access to their wards' performance as also could track their day to day activities. Besides the home and class assignments of the children could also be viewed and managed through this portal.</p>
                      <br/>
                </div>
                <hr/>
                    <div className="col-12">
                      <h3 className="subhead">Counselling Department</h3>
                      <div className="facilities-img left-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci10.jpg" alt="mgmb"/>
                      </div>
                      <p className="first-letter">  A counsellor is appointed to address the issues of adolescence / social/ psychological problems etc by counselling and consoling the students besides conducting periodic workshops.</p>
                      <br/>
                    </div>
                <hr/>
                <div className="col-12">
                      <h3 className="subhead">General Assembly</h3>
                      <div className="facilities-img1 right-block">
                        <img className="img-fluid" src="https://webapi.entab.info/api/image/MGMB/public/Images/faci11.jpg" alt="mgmb"/> 
                      </div>
                      <p className="first-letter"> There will be two General Assemblies of students in a week, one on Mondays and the other on Fridays. Assemblies shall be conducted in the quadrangle adjacent to the class rooms. Both the Weekly Assemblies shall be conducted during zero periods without affecting the number of working periods.</p>
                      <br/>
                </div>
              </div>
            </div>
        </div>
     </>
  )
}

export default Facilities

