import React, {useEffect } from 'react';
import { Link } from 'react-router-dom' 
const SamplePaper = () => {
  useEffect(() => {
    document.title = 'Sample Paper - Mar Gregorios Memorial Hr. Sec. School, Bokaro';  
}, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Academic</li>
                 <li>Sample Paper</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Sample Paper</h1>
                    </div>
                </div>
                <div className="row">
      <div className="col-lg-4 col-md-6"> <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/mathematics-standard-class-X-model-question-paper-2023-24.pdf" target="_blank"> <div className="sample-blk"> <p>Mathematics Standard Model Question Paper 2023-24 - Class X</p> <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
      <div className="col-lg-4 col-md-6"> <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/CBSE-2023-100-minute-100-conceptual-questions-with-solution.pdf" target="_blank"> <div className="sample-blk"> <p>CBSE 2023 100 minute 100  conceptual questions with solution.</p> <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
      <div className="col-lg-4 col-md-6"> <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/New-Physics-100-solved-questions-in-100-minutes-2022.pdf" target="_blank"> <div className="sample-blk"> <p>New Physics  100 solved  questions in 100 minutes. 2022</p> <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/> </div> </Link> </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/SYLLABUS-BREAK-UP-YEAR-2021-2022.pdf" target="_blank">
            <div className="sample-blk">
                <p>SYLLABUS BREAK-UP YEAR 2021-2022</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-01-for-board-exam-2020-21.pdf" target="_blank">
            <div className="sample-blk">
                <p>Maths (Class-XII-Sample Paper Test-01 for Board Exam-2020-21)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-01-for-board-exam-2020-21-answers.pdf" target="_blank">
            <div className="sample-blk">
                <p>Maths (Class-XII-Sample Paper Test 01 for Board Exam-2020-21 Answers)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>   
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-02-for-board-exam-2020-21.pdf" target="_blank">
            <div className="sample-blk">
                <p>Maths (Class -XII Sample Paper test 02 for Board Exam 2020-21)  </p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-02-for-board-exam-2020-21-answers.pdf">
            <div className="sample-blk">
                <p>Maths (Class XII Sample Paper Test-02 for Board Exam-2020-21 Answers)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-06-for-board-exam-2020-21.pdf">
            <div className="sample-blk">
                <p>Maths (Class-XII-Sample Paper Test-06 for Board Exam-2020-21)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/maths-class-xii-sample-paper-test-06-for-board-exam-2020-21-answers.pdf">
            <div className="sample-blk">
                <p>Maths (Class XII Sample Paper Test 06 for Board Exam 2020-21 Answers)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/organic-important-reactions.pdf">
            <div className="sample-blk">
                <p>Organic Important Reactions</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/physics-class-xii-sample-paper-test-01-for-2020-21.pdf">
            <div className="sample-blk">
                <p>Physics (Class-XII Sample Paper Test 01 for 2020-21)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/physics-class-xii-sample-paper-test-01-for-2020-21-answers.pdf">
            <div className="sample-blk">
                <p>Physics (Class XII Sample Paper Test 01 for 2020-21 Answers)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/physics-class-xii-sample-paper-test-02-for-2020-21.pdf">
            <div className="sample-blk">
                <p>Physics (Class XII Sample Paper Test 02 for 2020-21)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/physics-class-xii-sample-paper-test-02-for-2020-21-answers.pdf">
            <div className="sample-blk">
                <p>Physics (Class XII Sample Paper Test 02 for 2020-21-answers)</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/VEDANTU-PHYSICS-FORMULA-SHEET.pdf">
            <div className="sample-blk">
                <p>Vedantu Physics Formula Sheet</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/Class-10-IT-402-sample-paper.pdf">
            <div className="sample-blk">
                <p>Class 10 IT Sample Paper</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/SAMPLE-QUESTION-PAPER-CLASS-12-ENGLISH-CORE.pdf">
            <div className="sample-blk">
                <p>Sample Question Paper Class 12  English Core</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/CBSE-10-years-sample-papers-SST.pdf">
            <div className="sample-blk">
                <p>CBSE 10 Years Sample Papers SST</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/CBSE-10-Years-Sample-Papers.pdf">
            <div className="sample-blk">
                <p>CBSE 10 Years Sample Papers</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/CBSE-CLASS-10-SCIENCE-MIND-MAPS.pdf">
            <div className="sample-blk">
                <p>CBSE Class X Science Mind Maps</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="https://webapi.entab.info/api/image/MGMB/public/pdf/GOLDEN CBSE Board Sample Paper2021.pdf">
            <div className="sample-blk">
                <p>GOLDEN CBSE Board Sample Paper 2020-2021 English Language &amp;  Literature - X</p>
                <img alt="mgm" src="https://webapi.entab.info/api/image/MGMB/public/Images/pdf.png" className="img-fluid"/>
            </div>
          </Link>
        </div>
      </div>
            </div>
        </div>
     </>
  )
}

export default SamplePaper

