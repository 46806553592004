import React from 'react'; 
import VisitorCounter from'../Component/VisitorCounter'
const Footer = () => { 
    return (
        <>
            <div className="footer padded-sec">
                 <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-12'>
                    <div className='title'>
                <p>contact us</p>
                <h2>get in touch</h2>
              </div>  
                      </div>
                    <div className='col-md-12'>
                      <div className="footer-flex">
                        <div className='contact_details'>
                          <div>
                          <i className="bi bi-telephone-fill"></i>
                            <div>
                            Senior Wing: 9470970651, 06542-269783<br></br>
                            Primary Wing: 9470591858, 06542-266778
                            </div>
                          </div>
                          <div>
                          <i className="bi bi-envelope-fill"></i>
                            <div>mgmhsschool_bokaro@yahoo.in
                            </div>
                          </div>
                          <div>
                          <i className="bi bi-geo-alt-fill"></i>
                            <div>Sector- IV/F, Bokaro Steel City, Jharkhand -827004
                            </div>
                          </div>
                        </div>
                        <div><iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3653.944822894298!2d86.15729837533375!3d23.67793112871985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSector-%20IV%2FF%2C%20Bokaro%20Steel%20City%2C%20Jharkhand%20-827004!5e0!3m2!1sen!2sin!4v1721380343560!5m2!1sen!2sin" width="600" height="450"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='ftr-logo'>
                        <img src="https://webapi.entab.info/api/image/MGMB/public/Images/logo.png" />
                        <ul>
                          <li><a href="https://instagram.com/mgmbokaro?igshid=YmMyMTA2M2Y=" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/insta.png" /></a></li>
                          <li><a href="https://www.facebook.com/mgmhssbokaro?mibextid=ZbWKwL" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/facebook.png" /></a></li>
                          <li><a href="https://www.youtube.com/channel/UCF6_t0G_mmM9Ba31BbVvBZQ" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/youtube.png" /></a></li>
                          <li><a href="https://www.linkedin.com/in/mgmhss-bokaro-6791b0268/" target="_blank"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/linkdin.png" /></a></li>
                          <li><VisitorCounter/></li>
                        </ul>
                      </div>
                    </div>
                    
                    {/* <div className='col-md-6'>
                      <div className="mobile-app">
                        <div>
                          <p><b>Download Mobile App </b>A Common Platform For <b>Educators, Parents & Teachers</b></p>
                          <div className='mobile-link'>
                            <img src="https://webapi.entab.info/api/image/MGMB/public/Images/app-store.png" />
                            <img src="https://webapi.entab.info/api/image/MGMB/public/Images/playstore.png" />
                          </div>
                          <p className='code'>Use School Code :</p>
                        </div>
                        <div>
                          <img src='https://webapi.entab.info/api/image/MGMB/public/Images/mobile.png' />
                        </div>
                      </div>
                    </div> */}
                  </div>
                 </div>
            </div>
            <div className='copyright'>
            ©️Copyright 2024 MGM Bokaro, All Rights Reserved | Created by Entab Infotech : CampusCare®️  
            </div>
        </>
    );
}

export default Footer;
